<template>
  <div class="page">
    <van-row class="top">
      <van-col span="6"><van-icon name="user-circle-o" size="50px" /></van-col>
      <van-col span="8">
        <van-row>
          <van-col span="24" class="name"
            >{{ seller.name }} <van-icon name="edit"
          /></van-col>
        </van-row>
        <van-row>
          <van-col span="24" class="grade"
            ><van-tag :color="COLOR_M">{{ seller.gradeName }}</van-tag></van-col
          >
        </van-row>
      </van-col>
      <van-col span="10">
        <van-row>
          <van-col span="24" class="expire color-s1"
            >{{ seller.expireDate }}到期</van-col
          >
        </van-row>
        <van-row>
          <van-col span="24" class="buy"
            ><van-tag plain size="mini" :color="COLOR_M" @click="buy"
              ><van-icon name="cart-o" /> 立即续费</van-tag
            ></van-col
          >
        </van-row>
      </van-col>
    </van-row>
    <div class="wallet">
      <van-row>
        <van-col span="5" class="icon"
          ><van-icon name="balance-pay" size="40px"
        /></van-col>
        <van-col span="10" class="amount">￥{{ wallet.coinBalance }}元</van-col>
        <van-col span="9" class="more"
          ><van-button
            plain
            :color="COLOR_S1"
            icon="balance-list-o"
            size="mini"
            @click="walletRecord"
            >明细</van-button
          ><van-button
            plain
            :color="COLOR_S2"
            icon="cash-back-record"
            size="mini"
            @click="walletExtract"
            >提现</van-button
          ></van-col
        >
      </van-row>
    </div>
    <van-row class="settle">
      <van-col span="8" class="bdc-r1s-gray"
        ><div class="amount color-m">{{ settleCount.prior }}元</div>
        <div class="text">待办理收益</div></van-col
      >
      <van-col span="8" class="bdc-r1s-gray"
        ><div class="amount color-s1">{{ settleCount.wait }}元</div>
        <div class="text">待结算收益</div></van-col
      >
      <van-col span="8"
        ><div class="amount color-s2">{{ settleCount.complete }}元</div>
        <div class="text">已结算收益</div></van-col
      >
    </van-row>
    <div class="advice" @click="payment()">
      <van-image
        class="img"
        :src="require('../../../assets/images/seller/adv-01.jpg')"
      ></van-image>
    </div>
    <div class="navigate">
      <van-cell is-link to="index" @click="settle()">
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-settle"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 订单结算</van-col>
          </van-row>
        </template></van-cell
      >
      <van-cell @click="order()" is-link>
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-order"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 订单详情</van-col>
          </van-row>
        </template></van-cell
      >
      <van-cell @click="task()" is-link>
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-verify"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 考核任务</van-col>
          </van-row>
        </template></van-cell
      >
      <van-cell @click="love()" is-link>
        <template #title>
          <van-row>
            <van-col span="2"
              ><svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-love-1"></use></svg
            ></van-col>
            <van-col span="20" class="name"> 智能婚恋</van-col>
          </van-row>
        </template></van-cell
      >
    </div>
    <Tabbar active="manage" />
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import Tabbar from '../common/Tabbar.vue'
import Loading from '../../module/common/Loading.vue'
import Share from '../../module/common/Share.vue'
import { Icon, Tag, Cell, Image } from 'vant'
import '../../../assets/icon/font-icon.js'
export default {
  components: {
    Tabbar: Tabbar,
    Loading: Loading,
    Share: Share,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Cell.name]: Cell,
    [Image.name]: Image
  },
  data () {
    return {
      loadingShow: false,
      seller: {},
      settleCount: { prior: 0, wait: 0, complete: 0 },
      wallet: {}
    }
  },
  mounted () {
    this.retrieveSeller()
    this.retrieveSellerCount()
    this.retrieveInviterCount()
    // this.retrieveRefereeCount()
    this.$refs.share.default('seller')
  },
  methods: {
    order () {
      this.$router.push({ path: '/seller/order' })
    },
    task () {
      this.$router.push({ path: '/seller/task' })
    },
    settle () {
      this.$router.push({ path: '/seller/settle' })
    },
    async retrieveWallet () {
      var pd = { merchantCode: this.seller.merchantCode }
      const { data: res } = await this.$http.post(this.UMS_URL + '/business/wallet/retrieveWallet', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.wallet = res.data
      }
    },
    walletRecord () {
      this.$router.push({ path: '/wallet/record/business', query: { merchantCode: this.seller.merchantCode } })
    },
    walletExtract () {
      this.$router.push({ path: '/wallet/extract/business', query: { merchantCode: this.seller.merchantCode } })
    },
    async retrieveSeller () {
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var pd = { code: seller }
      const { data: res } = await this.$http.post(this.UMS_URL + '/sell/seller/retrieveSeller', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.seller = res.data
        this.retrieveWallet()
      }
    },
    async retrieveSellerCount () {
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var pd = { sellerCode: seller }
      const { data: res } = await this.$http.post(this.BMS_URL + '/overall/orderSET/retrieveOrderSETSellerCount', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.settleCount.prior += res.data.prior
        this.settleCount.wait += res.data.wait
        this.settleCount.complete += res.data.complete
      }
    },
    async retrieveInviterCount () {
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var pd = { sellerCode: seller }
      const { data: res } = await this.$http.post(this.BMS_URL + '/overall/orderSET/retrieveOrderSETInviterCount', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.settleCount.prior += res.data.prior
        this.settleCount.wait += res.data.wait
        this.settleCount.complete += res.data.complete
      }
    },
    async retrieveRefereeCount () {
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var pd = { refereeCode: user }
      const { data: res } = await this.$http.post(this.BMS_URL + '/overall/orderSET/retrieveOrderSETRefereeCount', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.settleCount.prior += res.data.prior
        this.settleCount.wait += res.data.wait
        this.settleCount.complete += res.data.complete
      }
    },
    signIn () {
      this.$dialog.alert({
        title: '提示信息',
        message: '签到成功'
      })
    },
    walletDetail () {
      this.$dialog.alert({
        title: '提示信息',
        message: '暂无交易信息'
      })
    },
    async payment () {
      var code = window.sessionStorage.getItem(this.SESSION_USER)
      var amount = 9.9
      this.loadingShow = true
      var serial = window.sessionStorage.getItem(this.SESSION_SERIAL)
      this.orderCode = code
      this.amount = amount
      var pd = {
        appid: 'wxa8145a74e5f2b39a',
        amount: this.amount * 100,
        attach: this.taskCode,
        description: '高考咨询服务支付',
        body: '高考咨询服务支付',
        detail: '',
        business: this.code,
        openid: serial
      }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/createJsapiPay', this.$qs.stringify(pd))
      if (res.status === '200') {
        var that = this
        this.payOrderCode = res.data.code
        var param = res.data.param
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest', param,
          function (rs) {
            if (rs.err_msg === 'get_brand_wcpay_request:ok') {
              that.retrievePayStatus()
            } else {
              that.loadingShow = false
            }
          })
      }
    },
    async retrievePayStatus () {
      var pd = { appid: 'wxa8145a74e5f2b39a', code: this.payOrderCode }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/retrievePayStatus', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$dialog.alert({
          title: '提示信息',
          message: '支付成功'
        })
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '支付失败'
        })
      }
      this.loadingShow = false
    },
    buy () {
      this.$router.push({ path: '/seller/buy' })
    },
    async love () {
      const { data: res } = await this.$http.post(this.UMS_URL + '/supply/supplier/retrieveLoginSupplierUser')
      if (res.status === '200') {
        window.sessionStorage.setItem(this.SESSION_SUPPLIER, res.data.supplierCode)
        this.$router.push({ path: '/love/release' })
      } else {
        this.$dialog.confirm({
          title: '提示信息',
          message: '确认开通婚恋服务吗?'
        })
          .then(() => {
            this.openSupplierService()
          })
      }
    },
    async openSupplierService () {
      var pd = { merchantCode: this.seller.merchantCode, module: 'LOV' }
      const { data: res } = await this.$http.post(this.UMS_URL + '/supply/supplier/openService', this.$qs.stringify(pd))
      if (res.status === '200') {
        window.sessionStorage.setItem(this.SESSION_SUPPLIER, res.data.supplierCode)
        this.$router.push({ path: '/love/release' })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  height: 100vh;
}
.top {
  padding: 15px 0px 5px 0px;
  .name {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    height: 20px;
    line-height: 20px;
  }
  .grade {
    text-align: left;
    height: 20px;
    line-height: 20px;
  }
  .task {
    height: 50px;
    line-height: 50px;
  }
  .expire {
    margin-top: 5px;
    text-align: center;
    height: 20px;
    line-height: 20px;
  }
  .buy {
    text-align: center;
    height: 20px;
    line-height: 20px;
  }
}
.wallet {
  width: 96%;
  height: 60px;
  border-radius: 5px;
  margin: 5px 0px;
  margin-left: 2%;
  color: #fff;
  background: -webkit-gradient(linear, 0 0, 100% 0, from(#00cc99), to(#00cc99));
  .icon {
    margin-top: 8px;
    text-align: right;
  }
  .amount {
    font-size: 18px;
    font-weight: 600;
    height: 60px;
    line-height: 60px;
    text-align: left;
  }
  .more {
    height: 52px;
    line-height: 52px;
  }
}
.settle {
  padding: 10px 0px;
  background-color: #fff;
  .amount {
    font-size: 16px;
    font-weight: 600;
    padding-top: 10px;
    height: 25px;
    line-height: 25px;
  }
  .text {
    height: 18px;
    line-height: 18px;
    padding-bottom: 10px;
    color: #bababa;
  }
}
.advice {
  width: 100%;
}
.navigate {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 50px;
  .icon {
    margin-top: 3px;
    width: 20px;
    height: 20px;
  }
  .name {
    font-size: 15px;
    height: 25px;
    line-height: 25px;
  }
}
</style>
