<template>
  <van-row>
    <van-col span="24">
      <van-tabbar
        v-model="activeTab"
        active-color="#00CC99"
        inactive-color="#000"
      >
        <van-tabbar-item name="home" icon="home-o" @click="home()"
          >首页</van-tabbar-item
        >
        <van-tabbar-item name="site" icon="search" @click="help()"
          >帮助</van-tabbar-item
        >
        <van-tabbar-item name="own" icon="user-o" @click="own()"
          >我的</van-tabbar-item
        >
        <!-- <van-tabbar-item name="manage" icon="cluster-o" @click="manage()"
          >管理</van-tabbar-item
        > -->
      </van-tabbar>
    </van-col>
  </van-row>
</template>
<script>
export default {
  data () {
    return {
      activeTab: this.active
    }
  },
  props: { active: { type: String } },
  mounted () {
  },
  methods: {
    home () {
      this.$router.push({ path: '/seller/home' })
    },
    help () {
      this.$router.push({ path: '/mde/help/issue' })
    },
    own () {
      this.$router.push({ path: '/seller/own' })
    }
  }
}
</script>
<style lang="less" scoped>
</style>
